import axios from 'axios';
import useAlertReducer from '../components/Login/reducer/AlertReducer';

const url = process.env.REACT_APP_API_ENDPOINT;

const Gateway = axios.create({
  baseURL: `${url}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

Gateway.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    const decryptedAccessToken = token;
    if (decryptedAccessToken)
      config.headers = {
        ...config.headers,
        Authorization: decryptedAccessToken,
      };
    return config;
  },
  (error) => {
    console.log('Interceptor error:', error);

    return Promise.reject(error);
  }
);

Gateway.interceptors.response.use(
  (response) => response,
  ({ response }) => {
    const { error } = useAlertReducer.getState();
    if (response?.data?.statusCode === 401) {
      error(response?.data?.message ?? response?.message);
      // setTimeout(() => {
      //   window.location.href = '/';
      // }, 3000);
      removeItem('accessToken');
      removeItem('authData');
    } else if (response?.data?.statusCode) {
      // error(response?.data?.message ?? response?.message);
    }
    return Promise.reject(response);
  }
);

export default Gateway;

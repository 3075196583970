// React Stuff
import React from 'react';
import { UserContext } from '../../UserContext';
// DRD-Specific Functions & Components
import PositionButton from './PositionButton';
import getAvailableActions from '../functions/getAvailableActions';
import getActionDescription from '../functions/getActionDescription';
import RangeDisplayContainer from './RangeDisplayContainer';
import getBetColors from '../functions/getBetColors';
import TreeInfoPane from './TreeInfoPane';
// Shared Functions
import fetchTreeInfo from '../../Shared/functions/fetchTreeInfo';
import fetchTreeMetadata from '../../Shared/functions/fetchTreeMetadata';
import getSeatNamesFromNumberOfSeats from '../../Shared/functions/getSeatNamesFromNumberOfSeats';
import onMobile from '../../Mobile/onMobile';
// Helpers, Icons
import { lastElement, getPositionFromAction, isEmpty } from '../../../helpers';
import MultiTreeSelector from '../../Shared/components/MultiTreeSelector';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import fetchAvailableRanges from '../../Shared/functions/fetchAvailableRanges';
import subscriptionReducer from '../../Subscription/reducer/subscriptionReducer';
import Loading from '../../Loading';

class DRD extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionStrings: [],
      treesInfo: [],
      availableActions: [],
      // Each "container" can hold multiple RDs, one for each tree
      rangeDisplayContainers: [],
      spawnLocation: null,
      treeInfoPaneOpen: false,
    };
  }

  componentDidMount() {
    // Initialize the state with default spawn location
    this.setDefaultSpawnLocation();
  }
  
  componentDidUpdate(prevProps) {
    // Check if subscription_status has changed
    if (
      prevProps.profileData?.subscription_status !==
      this.props.profileData?.subscription_status
    ) {
      this.setDefaultSpawnLocation();
    }
    if(this.state.loading && this.context.currentTrees.length > 0){
      if (this.context.currentTrees.length > 3)
        this.context.handleTreesChange(this.context.currentTrees.slice(0, 3));
      this.loadTrees();
    }
  }
  
  setDefaultSpawnLocation() {
    if (this.props.profileData?.subscription_status) {
      const drdContainer = document.getElementById('drd-container');
      if (drdContainer) {
        const rect = drdContainer.getBoundingClientRect();
        if (!onMobile()) {
          this.setState({ spawnLocation: { x: rect.width, y: 0 } });
        } else {
          this.setState({ spawnLocation: { x: 0, y: 0 } });
        }
      } else {
        // Fallback if `drd-container` is not found
        this.setState({ spawnLocation: { x: 0, y: 0 } });
      }
    }
  }

  // componentDidUpdate(){
    
  // }
  loadTrees = async () => {
    // not sure why, but if there are no trees selected currentTrees becomes an object
    if (!Array.isArray(this.context.currentTrees)) return;
    const treeInfoPromises = [];
    const metadataPromises = [];
    const availableActions = [];
    const availableRangePromises = [];
    const actionStrings = [];
    this.context.currentTrees.forEach((tree) => {
      treeInfoPromises.push(fetchTreeInfo(tree.id));
      metadataPromises.push(fetchTreeMetadata(tree.id));
      availableRangePromises.push(fetchAvailableRanges(tree.id));
    });
    const treesInfo = await Promise.all(treeInfoPromises);
    const metadata = await Promise.all(metadataPromises);
    const availableRangeArray = await Promise.all(availableRangePromises);
    treesInfo.forEach((tree, i) => {
      actionStrings.push('');
      tree.availableRanges = availableRangeArray[i];
      availableActions.push(getAvailableActions(availableRangeArray[i]));
    });
    this.setState({
      treesInfo,
      metadata,
      availableActions,
      actionStrings,
      loading: false,
    });
  };

  getPositionButtons = () => {
    const positionButtonArray = [];
    const seatNames = getSeatNamesFromNumberOfSeats(
      this.state.treesInfo[0].seats
    );
    const betColors = getBetColors(this.state.actionStrings[0], seatNames);
    seatNames.forEach((pos) => {
      const isStraddle =
        this.state.treesInfo[0].straddle && pos === seatNames[0];
      const canAct = Object.keys(this.state.availableActions[0]).includes(pos);
      const isActing =
        this.state.actionStrings[0] &&
        getPositionFromAction(
          lastElement(this.state.actionStrings[0].split(','))
        ) === pos;
      positionButtonArray.push(
        <PositionButton
          key={pos}
          position={!isStraddle ? pos.toUpperCase() : 'Straddle'}
          canAct={canAct}
          isActing={isActing}
          betColor={betColors[pos]}
          handleLeftClick={() => this.handlePosBtnLeftClick(pos)}
          handleRightClick={(e) => this.handlePosBtnRightClick(e, pos)}
        />
      );
    });
    return positionButtonArray;
  };

  handlePosBtnLeftClick = (pos) => {
    const newActionStrings = [];
    const newAvailableActions = [];
    // get the new action strings for each tree, then use those to get the new availableActions for each tree
    for (let i = 0; i < this.context.currentTrees.length; i++) {
      const newActionString = this.getActionString(
        pos,
        this.state.actionStrings[i],
        i
      );
      newActionStrings.push(newActionString);
      newAvailableActions.push(
        getAvailableActions(
          this.state.treesInfo[i].availableRanges,
          newActionString
        )
      );
    }
    // if the primary tree has no available actions, return, otherwise update state
    if (isEmpty(newAvailableActions[0])) return;
    this.setState({
      actionStrings: newActionStrings,
      availableActions: newAvailableActions,
    });
  };

  getActionString = (pos, actionString, index) => {
    let actionArray = actionString ? actionString.split(',') : [];
    const actingPlayer =
      actionArray.length > 0
        ? getPositionFromAction(lastElement(actionArray))
        : null;
    if (isEmpty(this.state.availableActions[index])) return 'noactions';
    // 1) If non acting position is clicked, make its first option the actionString
    if (actingPlayer !== pos) {
      actionArray = this.state.availableActions[index][pos][0];
    }
    // 2) If 'isActing' position was clicked - edit or remove last item in actionString
    else {
      const previousActionString = actionArray.length
        ? actionArray.slice(0, actionArray.length - 1).toString()
        : '';
      // gets an array of 'alternate' previous actions, usually just raise or raise + call

      const previousPossibleActions = getAvailableActions(
        this.state.treesInfo[index].availableRanges,
        previousActionString
      )[pos];
      const alternateActionIndex =
        previousPossibleActions.indexOf(actionArray.toString()) + 1;
      // if you are already in the last possible action in the list, go back to previous action
      if (alternateActionIndex === previousPossibleActions.length)
        actionArray.pop();
      // if not, update the action string, then check if there are any available actions. If there aren't, then go back
      else {
        actionArray = previousPossibleActions[alternateActionIndex].split(',');
        if (
          isEmpty(
            getAvailableActions(
              this.state.treesInfo[index].availableRanges,
              actionArray.length ? actionArray.toString() : ''
            )
          )
        )
          actionArray.pop();
      }
    }
    // 3) Return updated action string
    const newActionString = actionArray.length ? actionArray.toString() : '';
    return newActionString;
  };

  handlePosBtnRightClick = (e, pos) => {
    e.preventDefault();
    if (!this.state.availableActions[0][pos]) return;
    const rangeDisplayContainers = this.state.rangeDisplayContainers;
    const newRDContainer = this.state.treesInfo.map((tree, index) => {
      return {
        position: pos,
        actionString: this.state.actionStrings[index],
        ranges: this.state.availableActions[index][pos],
        treeID: this.context.currentTrees[index].id,
        treeInfo: this.state.treesInfo[index],
      };
    });
    rangeDisplayContainers.push(newRDContainer);
    this.clearSelection();
    this.setState({ rangeDisplayContainers });
  };

  getRangeDisplayContainerComponents = () => {
    const rdContainerComponents = [];
    this.state.rangeDisplayContainers.forEach((rdc, i) => {
      rdContainerComponents.push(
        <RangeDisplayContainer
          key={i}
          rdIndex={i}
          rangeDisplays={rdc}
          spawnLocation={this.state.spawnLocation}
          closeRangeDisplayContainer={(e) =>
            this.closeRangeDisplayContainer(e, i)
          }
        />
      );
    });
    return rdContainerComponents;
  };

  closeRangeDisplayContainer = (e, i) => {
    e.preventDefault();
    const rangeDisplayContainers = this.state.rangeDisplayContainers;
    delete rangeDisplayContainers[i];
    this.setState({ rangeDisplayContainers });
  };

  clearSelection = () => {
    const actionStrings = [];
    const availableActions = [];
    this.state.treesInfo.forEach((tree) => {
      actionStrings.push('');
      availableActions.push(getAvailableActions(tree.availableRanges));
    });
    this.setState({ actionStrings, availableActions });
  };

  clearAllRangeDisplayContainers = () =>
    this.setState({ rangeDisplayContainers: [] });

  render() {
    const { profileData, isProfileLoading } = this.props;

    // profileData?.subscription_status = false;
    let rdContainerComponents, seatClass;
    let arrow = this.state.treeInfoPaneOpen ? (
      <ArrowDropUpIcon />
    ) : (
      <ArrowDropDownIcon />
    );
    let positionButtons = (
      <div className="pos-btn-container drd-err-msg">Select a tree</div>
    );
    if (!this.state.loading && this.state.treesInfo.length) {
      seatClass = this.state.treesInfo[0].seats > 6 ? 'full-ring' : 'six-max';
      rdContainerComponents = this.getRangeDisplayContainerComponents();
      positionButtons = (
        <div className={`pos-btn-container ${seatClass}`}>
          {this.getPositionButtons()}
        </div>
      );
    }

    return (
      <>
        {isProfileLoading ? (
          <Loading />
        ) : (
          <>
            {!profileData?.subscription_status ? (
              <div className="drd-txt">
                <span>
                  You don't seem to have a subscription. Kindly contact the
                  admin
                </span>
              </div>
            ) : (
              <div id="drd-container">
                <p className="drd-description">
                  {getActionDescription(this.state.actionString)}
                </p>
                {positionButtons}
                <MultiTreeSelector
                  loadTrees={this.loadTrees}
                  selectionLimit={3}
                  playerCountMatch={true}
                />
                <div
                  className={
                    isEmpty(this.context.currentTrees)
                      ? 'display-none'
                      : 'drd-tree-info-label'
                  }
                  onClick={() =>
                    this.setState({
                      treeInfoPaneOpen: !this.state.treeInfoPaneOpen,
                    })
                  }>
                  <div className="arrow-drop-container">Tree Info{arrow}</div>
                </div>
                <TreeInfoPane
                  treeInfoPaneOpen={this.state.treeInfoPaneOpen}
                  treesInfo={this.state.treesInfo}
                  metadata={this.state.metadata}
                />
                <div className="drd-clear-btn-container">
                  <button
                    className="drd-clear-btn"
                    onClick={this.clearSelection}>
                    Clear Selection
                  </button>
                  <button
                    className="drd-clear-btn"
                    onClick={this.clearAllRangeDisplayContainers}>
                    Close all Ranges
                  </button>
                </div>
                {rdContainerComponents}
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

// Map Redux state to component props
function withUserProfile(WrappedComponent) {
  return function WrappedComponentWithUserProfile(props) {
    const profileData = subscriptionReducer((state) => state.profileData);
    const isProfileLoading = subscriptionReducer(
      (state) => state.isProfileLoading
    );
    return (
      <WrappedComponent
        profileData={profileData}
        isProfileLoading={isProfileLoading}
        {...props}
      />
    );
  };
}

export default withUserProfile(DRD);

import React from "react";
import UploadDetails from "./UploadDetails";
import UploadDropzone from "./UploadDropzone";
import { nanoid } from "nanoid";
import base from "../../../base";
import { getDate, removeDuplicates } from "../../../helpers";
import { UserContext } from "../../UserContext";
import { withRouter } from "react-router-dom";
import userIsAdmin from "../../Shared/functions/userIsAdmin";
import userIsUploader from "../../Shared/functions/userIsUploader";

class Upload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// operational state
			treeID: `MKT-${nanoid(8)}`,
			detailsSubmitted: false,
			uploadingInProgress: false,
			// tree data state
			treeName: "",
			monkerFilename: "",
			gameType: "NLHE",
			rangeDataToUpload: {},
			availableRanges: [],
			seats: 6,
			stackDepth: 200,
			straddle: false,
			ante: 0,
			chipSize: 0.2, // most common default for AJ mass upload
			public: true,
			openSize: "",
			rake: "",
		};
	}

	removeFirebaseForbiddenChars = (string) => {
		// const forbiddenChars = [".", "$", "[", "]", "#", "/"];    ... I don't think the "." actually bricks anything
		let validatedString = string.replaceAll("$", "").replaceAll("[", "").replaceAll("]", "").replaceAll("/", "");
		return validatedString;
	};

	// Form input handlers go here
	handleNameChange = (e) => {
		this.setState({ treeName: this.removeFirebaseForbiddenChars(e.target.value) });
	};
	handleMonkerFilenameChange = (e) => {
		const newValue = this.removeFirebaseForbiddenChars(e.target.value);
		this.setState({ monkerFilename: newValue });
	};
	handleGameTypeChange = (e) => this.setState({ gameType: e.target.value });
	handleSeatsChange = (e) => this.setState({ seats: parseInt(e.target.value) });
	handleDepthChange = (e) => this.setState({ stackDepth: parseInt(e.target.value) * 2 });
	handleStraddleChange = (e) => this.setState({ straddle: e.target.value === "false" ? false : true });
	handleAnteChange = (e) => this.setState({ ante: parseFloat(e.target.value) });
	handleChipChange = (e) => this.setState({ chipSize: parseFloat(e.target.value) });
	handlePrivateChange = () => this.setState({ public: !this.state.public });
	handleMetaChange = (e, type) => {
		this.setState({ [type]: this.removeFirebaseForbiddenChars(e.target.value) });
	};

	// Makes the loading swirley ...
	toggleUploading = () => this.setState({ uploadingInProgress: !this.state.uploadingInProgress });
	// Puts the rangeData into state
	updateRangeDataToUpload = (rangeDataToUpload) => this.setState({ rangeDataToUpload });
	// Updates list of available ranges in state with the newest drag/dropped ones, removing duplicates
	updateAvailableRanges = (newRanges) => {
		const availableRanges = removeDuplicates(this.state.availableRanges.concat(newRanges));
		this.setState({ availableRanges });
	};

	// Checks if form has the proper information, then uploads it if so
	backToDetails = () => this.setState({ rangeData: {}, detailsSubmitted: false });
	submitDetails = (e) => {
		e.preventDefault();
		// Check that user has put in a name for the tree
		if (this.state.treeName === "") alert("Input a name for this tree");
		else this.setState({ detailsSubmitted: true });
	};

	// UPLOAD METADATA ONLY
	uploadMetadataOnly = async () => {
		if (!(await userIsAdmin(this.context.uid)) && !(await userIsUploader(this.context.uid))) {
			alert("Only users with Admin or Uploader privileges can upload a new tree");
			console.error("Only users with Admin or Uploader privileges can upload a new tree");
			return;
		}
		const treeID = this.state.treeID;
		const userInfo = this.context;

		base.post(`treePermissions/${userInfo.uid}/${treeID}`, { data: this.state.treeName });
		if (this.state.public) base.post(`publicTrees/${treeID}`, { data: this.state.treeName });

		const data = {
			metadata: {
				uploadedBy: userInfo.uid,
				allowedUsers: { [userInfo.uid]: true },
				timeUploaded: getDate(),
				treeName: this.state.treeName,
				monkerFilename: this.state.monkerFilename,
				public: this.state.public,
				openSize: this.state.openSize,
				rake: this.state.rake,
			},
			treeInfo: {
				gameType: this.state.gameType,
				seats: this.state.seats,
				stackDepth: this.state.stackDepth,
				straddle: this.state.straddle,
				ante: this.state.ante,
				chipSize: this.state.chipSize,
			},
			availableRanges: this.state.availableRanges,
		};
		base.update(`trees/${treeID}`, { data }).then(() => {
			alert(`Metadata Uploaded\n Tree ID is: ${this.state.treeID}`);
			this.props.history.push(``);
			location.reload();
		});
	};

	// FULL UPLOAD
	finalizeTreeSubmission = () => {
		// Tell 'em to wait if they click before upload is done
		if (this.state.uploadingInProgress === true) alert("Please wait for the tree to be finished importing");
		// Make sure there is some range data
		else if (Object.keys(this.state.rangeData).length === 0) alert("Make sure you upload a monkerviewer range folder!");
		// Upload it!
		else this.uploadTreeToDatabase();
	};
	uploadTreeToDatabase = async () => {
		if (!(await userIsAdmin(this.context.uid)) && !(await userIsUploader(this.context.uid))) {
			alert("Only users with Admin or Uploader privileges can upload a new tree");
			console.error("Only users with Admin or Uploader privileges can upload a new tree");
			return;
		}
		const treeID = this.state.treeID;
		const userInfo = this.context;
		base.post(`treePermissions/${userInfo.uid}/${treeID}`, { data: this.state.treeName });
		if (this.state.public) base.post(`publicTrees/${treeID}`, { data: this.state.treeName });

		const data = {
			metadata: {
				uploadedBy: userInfo.uid,
				allowedUsers: { [userInfo.uid]: true },
				timeUploaded: getDate(),
				treeName: this.state.treeName,
				monkerFilename: this.state.monkerFilename,
				public: this.state.public,
				openSize: this.state.openSize,
				site: this.state.site,
				stake: this.state.stake,
				rake: this.state.rake,
			},
			treeInfo: {
				gameType: this.state.gameType,
				seats: this.state.seats,
				stackDepth: this.state.stackDepth,
				straddle: this.state.straddle,
				ante: this.state.ante,
				chipSize: this.state.chipSize,
			},
			rangeData: this.state.rangeData,
			availableRanges: this.state.availableRanges,
		};
		base
			.update(`trees/${treeID}`, {
				data,
			})
			.then(() => {
				alert("Tree Uploaded");
				this.props.history.push(``);
				location.reload();
			});
	};

	render() {
		let display;
		if (this.state.detailsSubmitted === false) {
			display = (
				<UploadDetails
					handleNameChange={this.handleNameChange}
					handleMonkerFilenameChange={this.handleMonkerFilenameChange}
					treeName={this.state.treeName}
					monkerFilename={this.state.monkerFilename}
					handleGameTypeChange={this.handleGameTypeChange}
					handleSeatsChange={this.handleSeatsChange}
					handleDepthChange={this.handleDepthChange}
					handleStraddleChange={this.handleStraddleChange}
					handleAnteChange={this.handleAnteChange}
					handleChipChange={this.handleChipChange}
					handlePrivateChange={this.handlePrivateChange}
					handleMetaChange={this.handleMetaChange}
					submitDetails={this.submitDetails}
				/>
			);
		} else {
			display = (
				<UploadDropzone
					// state handlers/status references
					updateRangeDataToUpload={this.updateRangeDataToUpload}
					updateAvailableRanges={this.updateAvailableRanges}
					toggleUploading={this.toggleUploading}
					uploadingInProgress={this.state.uploadingInProgress}
					finalizeTreeSubmission={this.finalizeTreeSubmission}
					// tree params
					seats={this.state.seats}
					gameType={this.state.gameType}
					// ranges & file counts
					availableRanges={this.state.availableRanges}
					uniqueRangesUploadCount={Object.keys(this.state.rangeDataToUpload).length}
					// buttons
					backToDetails={this.backToDetails}
					uploadMetadataOnly={this.uploadMetadataOnly}
					finishUploading={this.finishUploading}
				/>
			);
		}

		return (
			<div>
				<h1 className="page-heading">Upload A Monker Tree</h1>
				<h3 style={{ background: "red", textAlign: "center" }}> TEMPORARILY DISABLED - ASK JAKE FOR MANUAL UPLOAD </h3>
				<h4 className={!this.state.detailsSubmitted ? "display-none" : ""}>{`${this.state.treeName} - ${this.state.treeID}`}</h4>
				{display}
			</div>
		);
	}
}

export default withRouter(Upload);
Upload.contextType = UserContext;

//TODO: Show tree details on Dropzone screen

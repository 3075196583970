import { useEffect, useState } from 'react';
import firebase from 'firebase/app';

import { Link } from 'react-router-dom';
import GoogleIcon from '../../../css/images/icon-google.svg';
import ButtonArrowIcon from '../../../css/images/btn-arrow.svg';
import hidePasswordIcon from '../../../css/images/icon-hide-password.svg';
import showPasswordIcon from '../../../css/images/icon-show-password.svg';
import useSignupReducer from '../reducer/signupReducer';
import useAlertReducer from '../../Login/reducer/AlertReducer';
// import { useGoogleLogin } from '@react-oauth/google';

export const PersonalInfo = ({ currentTab, switchTab, setData, data }) => {
    const auth = firebase.auth();
    const { error } = useAlertReducer((state) => state);
    // const datastore = new Datastore();
    const googleProvider = new firebase.auth.GoogleAuthProvider();

    const { personalInfo } = data;
    const [formData, setFormData] = useState({
        name: personalInfo?.name || '',
        // lastName: personalInfo?.lastName || '',
        email: personalInfo?.email || '',
        // phone: personalInfo?.phone || '',
        password: personalInfo?.password || '',
        confirmPassword: personalInfo?.confirmPassword || '',
        googleUserData: personalInfo?.googleUserData || '',
    });
    const [errors, setErrors] = useState({});
    const [isPasswordVisible, setIsPasswordVisible] = useState([]);

    const {
        checkEmail,
        errorMessage,
        successMessage,
        isCheckEmailLoading,
        emailFound,
    } = useSignupReducer((state) => state);

    const signUpWithGoogle = async () => {
        try {
            const result = await firebase
                .auth()
                .signInWithPopup(googleProvider);
            await checkEmail({ email: result.user?.email }, { isLogin: false });
            const updatedEmailFound = useSignupReducer.getState().emailFound;
            if (updatedEmailFound) {
                // firebase
                //     .auth()
                //     .signInWithPopup(googleProvider)
                //     .then(async (result) => {
                //         setData({
                //             ...data,
                //             personalInfo: {
                //                 ...formData,
                //                 name: result.user?.displayName,
                //                 // lastName: userInfo?.family_name,
                //                 email: result.user?.email,
                //                 googleUserData: result.user,
                //                 password: '',
                //                 confirmPassword: '',
                //             },
                //         });
                //         // checkEmail({ email: result.user?.email });
                //         switchTab('choosePlan');
                //     })
                //     .catch((err) => {});
                setData({
                    ...data,
                    personalInfo: {
                        ...formData,
                        name: result.user?.displayName,
                        // lastName: userInfo?.family_name,
                        email: result.user?.email,
                        googleUserData: result.user,
                        password: '',
                        confirmPassword: '',
                    },
                });
                switchTab('choosePlan');
            }
        } catch (err) {
            // 6. Handle errors during authentication or API calls
            console.error('Error during Google Sign-In:', err);
            error(err?.message ?? 'Technical Error, Please try again');
            // ... (e.g., display error message to the user)
        }
    };
    const togglePasswordVisibility = (value) => {
        setIsPasswordVisible((prevState) => {
            if (prevState.includes(value)) {
                return prevState.filter((item) => item !== value);
            } else {
                return [...prevState, value];
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'name' ? value : value.trim(),
        });
    };

    const passRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,15}$/;

    const validate = () => {
        let tempErrors = {};
        if (!formData.name) tempErrors.name = 'Name is required';
        // if (!formData.lastName) tempErrors.lastName = "Last Name is required";
        if (!formData.email) {
            tempErrors.email = 'Email is required';
        } else if (
            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ||
            /\..+@/.test(formData.email)
        ) {
            tempErrors.email = 'Email is not valid';
        }
        if (!formData.password) {
            tempErrors.password = 'Password is required';
        } else if (formData.password.length < 8) {
            // Minimum length should be 8 according to the regex
            tempErrors.password = 'Password must be at least 8 characters';
        } else if (formData.password.length > 15) {
            // Maximum length should be 15 according to the regex
            tempErrors.password = 'Password must be no more than 15 characters';
        } else if (!passRegex.test(formData.password)) {
            tempErrors.password =
                'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character';
        }
        if (formData.confirmPassword !== formData.password) {
            tempErrors.confirmPassword = 'Passwords do not match';
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    useEffect(() => {
        if (successMessage === 'Email does not exist') {
            switchTab('choosePlan');
            // setData({ ...data, personalInfo: formData });
        }
    }, [successMessage]);

    const handleSubmit = () => {
        if (validate()) {
            setData({ ...data, personalInfo: formData });
            checkEmail({ email: formData?.email }, { isLogin: false });
        }
    };

    return (
        <div
            className={`form-steps step-1 ${
                currentTab !== 'personalInfo' && 'd-none'
            }`}
        >
            <div className="forms-fields">
                <div className="signup-action-footer-inner">
                    <div className="input-wrp">
                        <button
                            className="btn btn-google-login"
                            onClick={() => signUpWithGoogle()}
                        >
                            <span className="ico">
                                <img src={GoogleIcon} alt="icon-google" />
                            </span>
                            <span className="txt">Sign up with Google</span>
                        </button>
                    </div>

                    <div className="sep-wrp">
                        <h3 className="sep-title">OR</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="input-wrp">
                            <label htmlFor="name" className="label req">
                                Full Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Full Name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                            {errors.name && (
                                <small className="text-danger">
                                    {errors.name}
                                </small>
                            )}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="input-wrp">
                            <label htmlFor="email" className="label req">
                                Email
                            </label>
                            <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Enter Email address"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {errors.email && (
                                <small className="text-danger">
                                    {errors.email}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-wrp">
                            <label htmlFor="password" className="label req">
                                Create Password
                            </label>
                            <div className="password-field-wrp">
                                <span
                                    className="show-hide"
                                    fdprocessedid="52cg5d"
                                    onClick={() =>
                                        togglePasswordVisibility('1')
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                    <img
                                        src={
                                            !isPasswordVisible.includes('1')
                                                ? hidePasswordIcon
                                                : showPasswordIcon
                                        }
                                        alt=""
                                        className="show"
                                    />
                                </span>
                                <input
                                    type={
                                        isPasswordVisible.includes('1')
                                            ? 'text'
                                            : 'password'
                                    }
                                    name="password"
                                    id="password"
                                    className="form-control"
                                    placeholder="Password"
                                    value={data.password || formData.password}
                                    onChange={handleInputChange}
                                />
                                {errors.password && (
                                    <small className="text-danger">
                                        {errors.password}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-wrp">
                            <label
                                htmlFor="confirmPassword"
                                className="label req"
                            >
                                Confirm Password
                            </label>
                            <div className="password-field-wrp">
                                <span
                                    className="show-hide"
                                    fdprocessedid="52cg5d"
                                    onClick={() =>
                                        togglePasswordVisibility('2')
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                    <img
                                        src={
                                            !isPasswordVisible.includes('2')
                                                ? hidePasswordIcon
                                                : showPasswordIcon
                                        }
                                        alt=""
                                        className="show"
                                    />
                                </span>
                                <input
                                    type={
                                        isPasswordVisible.includes('2')
                                            ? 'text'
                                            : 'password'
                                    }
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    className="form-control"
                                    placeholder="Confirm password"
                                    value={formData.confirmPassword}
                                    onChange={handleInputChange}
                                />
                                {errors.confirmPassword && (
                                    <small className="text-danger">
                                        {errors.confirmPassword}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signup-action-footer">
                    <a
                        href="https://preflopmastery.com/"
                        className="btn btn-primary actn-arrows prev back-hm"
                    >
                        <span className="ico">
                            <img src={ButtonArrowIcon} alt="next" />
                        </span>
                        <span className="txt">Back to Home</span>
                    </a>
                    <button
                        className="btn btn-primary actn-arrows next"
                        onClick={handleSubmit}
                    >
                        <span className="txt">
                            {isCheckEmailLoading ? 'Loading...' : 'Next'}
                        </span>
                        <span className="ico">
                            <img src={ButtonArrowIcon} alt="next" />
                        </span>
                    </button>
                </div>
                <div className="signup-action-footer-inner">
                    <div className="input-wrp">
                        <div className="input-wrp mt-4">
                            <p className="signup-txt">
                                Already have an account?{' '}
                                <Link
                                    to="/login"
                                    className="links links-primary"
                                >
                                    Login
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

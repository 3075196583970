import React, { useContext } from "react";
import { firestoredb } from "../base";
import { UserContext } from "./UserContext";

//TODO: trigger some sort of loading after a subscribe click (looks janky)

export default function Subscribe() {
	const context = useContext(UserContext);

	// figure out what other parameters I am supposed be giving to the checkout session to actually make things work or whatever
	const handleMonthlyCheckout = async () => {
		const priceID = process.env.REACT_APP_STRIPE_TEST_MONTHLY_PRICE;
		const docRef = await firestoredb.collection("customers").doc(context.uid).collection("checkout_sessions").add({
			price: priceID,
			success_url: window.location.origin,
			cancel_url: window.location.origin,
		});
		// Wait for the CheckoutSession to get attached by the extension
		docRef.onSnapshot((snap) => {
			const { error, url } = snap.data();
			if (error) {
				// Show an error to your customer and inspect your Cloud Function logs in the Firebase console.
				alert(`An error occured: ${error.message}`);
			}
			if (url) {
				// We have a Stripe Checkout URL, let's redirect.
				window.location.assign(url);
			}
		});
	};

	const handleLifetimeCheckout = async () => {
		const docRef = await firestoredb.collection("customers").doc(context.uid).collection("checkout_sessions").add({
			mode: "payment",
			price: process.env.REACT_APP_STRIPE_TEST_LIFETIME_PRICE, // One-time price created in Stripe
			success_url: window.location.origin,
			cancel_url: window.location.origin,
		});
		docRef.onSnapshot((snap) => {
			const { error, url } = snap.data();
			if (error) {
				// Show an error to your customer and inspect your Cloud Function logs in the Firebase console.
				alert(`An error occured: ${error.message}`);
			}
			if (url) {
				// We have a Stripe Checkout URL, let's redirect.
				window.location.assign(url);
			}
		});
	};

	return (
		<div>
			<div className="subscribe-cnt">
				<h1>Get a license for unrestricted, unlimited access to all of Preflop Mastery's features</h1>
				<div className="subscribe-card-cnt">
					<div className="subscribe-card">
						<h2 className="subscribe-card-label">Monthly</h2>
						<div>
							<h3 className="subscribe-price">$15 USD</h3>
							<h4 className="subscribe-freq">per month</h4>
						</div>
						<button className="subscribe-btn" onClick={() => handleMonthlyCheckout()}>
							Subscribe
						</button>
					</div>

					<div className="subscribe-card">
						<h2 className="subscribe-card-label">Lifetime</h2>
						<div>
							<h3 className="subscribe-price">$150 USD</h3>
							<h4 className="subscribe-freq">one time</h4>
						</div>
						<button className="subscribe-btn" onClick={() => handleLifetimeCheckout()}>
							Buy License
						</button>
					</div>
				</div>
			</div>

			<div className="subscribe-feature-list">
				<h2 className="subscribe-card-label">Features</h2>

				<ul>
					<li>Access to all game trees, for NLHE cash games, tournaments, and PLO</li>
					<li>View unlimited Range Displays for any positional matchup</li>
					<li>Practice reps in the Range Trainer and deep dive into your Training Data</li>
					<li>Export all ranges into a PIO format for simming beyond preflop</li>
				</ul>
			</div>
		</div>
	);
}

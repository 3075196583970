import { create } from 'zustand';
import subscriptionService from './subscriptionService';
import useAlertReducer from '../../Login/reducer/AlertReducer';
const subscriptionReducer = create((set) => ({
  isProfileLoading: false,
  cancelSubscriptionLoading: false,
  profileData: '',
  userExist:false,
  profile: async () => {
    try {
      set({ isProfileLoading: true, profileData: '' });
      const { data } = await subscriptionService.getUserProfile();
      set({ profileData: data.data.user, isProfileLoading: false , userExist: true});
      //   success(data && data.message);
    } catch (err) {
      console.log('err', err?.response?.data?.message);
      set({
        errorMessage: err?.response?.data?.message ?? err?.message,
        isPlanLoading: false,
        userExist: false,
      });
    }
  },
  cancelSubscription: async (payload) => {
    try {
      set({ isProfileLoading: true });
      const { data } = await subscriptionService.cancel(payload);
      // debugger;
      set({ cancelSubscriptionLoading: false });
      subscriptionReducer.getState().profile();
      // useAlertReducer(data && data.message);
    } catch (err) {
      console.log('err', err?.response?.data?.message);
      set({
        errorMessage: err?.response?.data?.message ?? err?.message,
        isPlanLoading: false,
      });
    }
  },
}));
export default subscriptionReducer;

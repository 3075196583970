import React from "react";
import Draggable from "react-draggable";
import { UserContext } from "../../UserContext";
import onMobile from "../../Mobile/onMobile";
import onTouchScreen from "../../Mobile/onTouchScreen";
import RangeDisplay from "./RangeDisplay";
import { ReorderRounded } from "@material-ui/icons";
import PLORangeDisplay from "./PLORangeDisplay";
let lastTap;

class RangeDisplayContainer extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			onMobile: onMobile(),
			onTouchScreen: onTouchScreen(),
		};
	}

	doubleTapHandler = (event) => {
		if (!this.state.onTouchScreen) return;
		let now = new Date().getTime();
		let timeSince = now - lastTap;
		if (timeSince < 400 && timeSince > 0) this.props.closeRangeDisplayContainer(event, this.props.rdIndex);
		lastTap = now;
	};

	getRangeDisplayComponents = () => {
		const rangeDisplayComponents = [];
		this.props.rangeDisplays.forEach((rd, i) => {
			if (rd.ranges) {
				if (rd.treeInfo.gameType === "NLHE") {
					rangeDisplayComponents.push(
						<RangeDisplay key={i} info={rd} handleRightClick={(e) => this.props.closeRangeDisplayContainer(e, i)} />
					);
				} else {
					rangeDisplayComponents.push(
						<PLORangeDisplay key={i} info={rd} handleRightClick={(e) => this.props.closeRangeDisplayContainer(e, i)} />
					);
				}
			} else {
				rangeDisplayComponents.push(<p className="no-range-available">{this.context.treePermissions[rd.treeID]} - No Range Available</p>);
			}
		});
		return rangeDisplayComponents;
	};

	render() {
		const dragAxis = this.state.onMobile ? "y" : "both";
		const rangeDisplayComponents = this.getRangeDisplayComponents(this.props.rangeDisplays);
		const defaultPosition = this.props.spawnLocation || { x: 0, y: 0 };
		return (
			<Draggable defaultPosition={defaultPosition} onMouseDown={this.doubleTapHandler} axis={dragAxis}>
				<div className="rd-container">{rangeDisplayComponents}</div>
			</Draggable>
		);
	}
}

export default RangeDisplayContainer;

import { Link } from "react-router-dom";
//import images
// import logo from "../../css/images/logo copy.png";
import logo from "../../css/images/preflop_logo.svg";
import trophyIcon from "../../css/images/trophy.svg";
import toolsIcon from "../../css/images/tools.svg";
import analyticsIcon from "../../css/images/analytics.svg";
import settingIcon from "../../css/images/setting.svg";
import feedbackIcon from "../../css/images/feedback.svg";
import subscriptionIcon from "../../css/images/subscription.svg";
import logoutIcon from "../../css/images/logout.svg";
//import styles
import "../../css/scss/header.scss";
import "../../css/scss/login.scss";
import "../../css/scss/common-modal.scss";
import "../../css/scss/login-modals.scss";
import "../../css/scss/footer.scss";
import { useEffect, useState } from "react";
import useLoginReducer from "../Login/reducer/LoginReducer";
import firebase from "firebase/app";
import history from "../../history";
import subscriptionReducer from "../Subscription/reducer/subscriptionReducer";

export default function Header({ privateMode, togglePrivateMode }) {
  const path=location.pathname;

  const { logout, isLoggedIn } = useLoginReducer((state) => state);
  const { profileData } = subscriptionReducer((state) => state);
  const [activeTab, setActiveTab] = useState(path ||"");
  const [state, setState] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth <= 992 && privateMode === true) {
  //       setIsExpanded(false);
  //     } else {
  //       setIsExpanded(false);
  //     }
  //   };

  //   // Check the window size on initial render
  //   handleResize();

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (state && !isExpanded && !privateMode) {
  //     window.location.reload();
  //   }
  //   if (!state && !isExpanded && privateMode === false) {
  //     window.location.reload();
  //   }
  // }, [state, isExpanded, privateMode]);

  useEffect(()=>{
    setActiveTab(path);
  },[path])

  const auth = firebase.auth();

  const handleMenuClick = (tab) => {
    // setActiveTab(tab);
    togglePrivateMode(false);
    setIsExpanded(true);
    setState(true);
  };

  const handleFirebaseLogOut = () => {
    auth
      .signOut()
      .then(async() => {
        // Sign-out successful.
        // history.push("/login");
        // window.location.href = "https://preflopmastery.com/";
        logout();
      })
      .catch((error) => {
        // An error happened.
      });
  };
  return (
    <header className="header subscription-header">
      <nav className="navbar navbar-expand-lg ">
        <div className="container custom-container">
          {privateMode || isLoggedIn ? (
            <Link className="navbar-brand" to="/home">
              <img src={logo} alt="" />
            </Link>
          ) : (
            <a className="navbar-brand" href="https://preflopmastery.com/">
              <img src={logo} alt="" />
            </a>
          )}
          {(privateMode || isExpanded) && (
            <>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                id="menu-toggle"
                onClick={() => {
                  // togglePrivateMode(!privateMode);
                  setIsExpanded(true);
                  setState(false);
                }}
              >
                <span />
              </button>
              <div
                className={`collapse navbar-collapse justify-content-center ${
                  !state && isExpanded ? "show" : ""
                }`}
                id="navbarNav"
              >
                <ul className="navbar-nav  mx-auto nav-inner">
                  <li
                    className="nav-item  d-flex align-items-center"
                    onClick={() => handleMenuClick(path)}
                  >
                    <Link
                      className={`nav-link ${
                        activeTab === "/drd" && "active"
                      }`}
                      to="/drd"
                    >
                      <span className="ico1">
                        <img src={toolsIcon} alt="Trainer" />
                      </span>
                      <span>Ranges</span>
                    </Link>
                  </li>
                  <li
                    className="nav-item d-flex align-items-center"
                    onClick={() => handleMenuClick(path)}
                  >
                    <Link
                      className={`nav-link ${
                        activeTab === "/trainer" && "active"
                      }`}
                      aria-current="page"
                      to="/trainer"
                    >
                      <span className="ico1">
                        <img src={trophyIcon} alt="Trainer" />
                      </span>

                      <span>Trainer</span>
                    </Link>
                  </li>
                  <li
                    className="nav-item  d-flex align-items-center"
                    onClick={() => handleMenuClick(path)}
                  >
                    <Link
                      className={`nav-link ${activeTab === "/data" && "active"}`}
                      to="/data"
                    >
                      <span className="ico1">
                        <img src={analyticsIcon} alt="Trainer" />
                      </span>
                      <span> Data</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <ul
                className="navbar-nav ms-lg-auto settings-nav"
                onClick={() => {
                  if (window.innerWidth <= 992) {
                    // togglePrivateMode(!privateMode);
                    setState(true);
                  }
                }}
              >
                <li className="nav-item dropdown">
                  <a
                    className="nav-link settings-ico"
                    // href="#"
                    id="settingsDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={settingIcon} alt="Settings" />
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-end settings-items"
                    aria-labelledby="settingsDropdown"
                  >
                    {/* <li className="nav-item  d-flex align-items-center">
                      <Link
                        to="/trees"
                        className="nav-item"
                        onClick={() => setActiveTab('')}>
                        <span className="ico2">
                          <img src={feedbackIcon} alt="Trainer" />
                        </span>
                        <span>Trees</span>
                      </Link>
                    </li> */}

                    <li className="nav-item  d-flex align-items-center">
                      <Link
                        to="/tools"
                        className="nav-item"
                        onClick={() => setActiveTab("")}
                      >
                        <span className="ico2">
                          <img src={toolsIcon} alt="Trainer" />
                        </span>
                        <span>Tools</span>
                      </Link>
                    </li>

                    <li className="nav-item  d-flex align-items-center">
                      <Link
                        to="/settings"
                        className="nav-item"
                        onClick={() => setActiveTab("")}
                      >
                        <span className="ico2">
                          <img src={settingIcon} alt="Trainer" />
                        </span>
                        <span>Settings</span>
                      </Link>
                    </li>

                    <li className="nav-item  d-flex align-items-center">
                      <Link
                        to="/feedback"
                        className="nav-item"
                        onClick={() => setActiveTab("")}
                      >
                        <span className="ico2">
                          <img src={feedbackIcon} alt="Trainer" />
                        </span>
                        <span>Feedback</span>
                      </Link>
                    </li>
                    {profileData.isVip===false && (
                      <li className="nav-item  d-flex align-items-center">
                        <Link
                          className="nav-item"
                          to="/subscription"
                          onClick={() => setActiveTab("")}
                        >
                          <span className="ico2">
                            <img src={subscriptionIcon} alt="Trainer" />
                          </span>
                          <span>Subscription</span>
                        </Link>
                      </li>
                    )}

                    {/* <li className="nav-item  d-flex align-items-center">
                      <span>
                        <img src={subscriptionIcon} alt="Trainer" />
                      </span>
                      <span>
                        <Link className="nav-item" to="/upload">
                          Upload Tree
                        </Link>
                      </span>
                    </li> */}

                    <li className="nav-item  d-flex align-items-center">
                      <a
                        // to="/"
                        // href="https://preflopmastery.com/"
                        className="nav-item"
                        onClick={(e) => { 
                          e.preventDefault();
                          handleFirebaseLogOut();
                        }}
                      >
                        <span className="ico2">
                          <img src={logoutIcon} alt="Trainer" />
                        </span>
                        <span>Logout</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}
